/*
 * @Date: 2024-07-24 17:31:14
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-02 13:32:52
 */
/**
 * 获取 DOM 元素
 */
export const getDom = <T extends HTMLElement>(
    selector: string,
    parent: HTMLElement | Document = document
): T | null => parent.querySelector(selector);

/**
 * 获取 Dom 元素集
 */
export const getDomList = <T extends HTMLElement>(
    selector: string,
    parent: HTMLElement | Document = document
): NodeListOf<T> | null => parent.querySelectorAll(selector);

/**
 * 通过类名查找最近父元素
 */
export const getParentToSelector = (element: HTMLElement, selector: string): HTMLElement | null => {
    return element.closest(selector);
};

/**
 * 获取元素某样式属性值
 */
export const getElCssValue = (element: HTMLElement, Property: string) => {
    return window?.getComputedStyle(element, null).getPropertyValue(Property);
};

/**
 * 设置样式
 */
export const setStyle = (prop: any, value: any, selector: HTMLElement | null) => {
    if (!selector) {
        throw new Error('selector is no found');
    } else if (!prop) {
        throw new Error('prop is no found');
    }
    selector.style[prop] = value;
};

/**
 * 判断是否存在该样式
 */
export const hasClass = (node: HTMLElement, className: string) => {
    if (node.getAttribute('class') && node.nodeName !== 'svg') {
        // 存在class属性
        return (
            node.getAttribute('class')!.indexOf(className) > -1 ||
            node.className?.indexOf(className) > -1
        );
    }
};
