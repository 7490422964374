/*
 * @Description: 本地存储 storage 命名规范
 * @Author: James324
 * @Date: 2023-08-22 17:18:01
 * @LastEditors: p彭 9878922+p-pengrulin@user.noreply.gitee.com
 * @LastEditTime: 2024-08-07 00:56:02
 */

/* Storage Variable */
export const STORAGE_PREFIX = '__be_art__';
export const GLOBAL_STATE = 'global_state'; // 全局 pinia store 状态存储名称, 存储值包括：用户 token, 用户信息等。
export const LOGIN_STATE = 'login_state';
export const SIDE_MENU_STATE = 'side_menu_state';
export const TAGS_VIEW_STATE = 'tags_view_state';
export const LOGIN_LOGIN_FORM = 'login_login_form';
export const HELP_STATE = 'help_state';
export const ADD_DATA_STORE = 'add_data_state';

/* Component Name */
export const LAYOUT = 'Layout';

/* Loading Table Id 1*/
export const MODAL_TABLE_ID = 'modal-table__level--1';

/**
 * @:  生成 id
 * @param {string} id
 * @param {string} prefix
 * @return {*}
 */
export const generateNameToLevel = (id: string, prefix: string = 'modal-table__level--') => {
    return `${prefix}${id}`;
};

/**
 * @: useMutationObserver配置
 * @return {*}
 */
export const mutationConfig = {
    attributes: true,
    childList: true,
    subtree: false
};
