<script setup lang="ts">
const isRouterAlive = ref(true);

/**
 * 重载页面，无感刷新
 */
const reload = () => {
    return new Promise(res => {
        isRouterAlive.value = false;
        nextTick(() => {
            isRouterAlive.value = true;
            res(true);
        });
    });
};

provide('reload', reload);
</script>

<template>
    <router-view> </router-view>
</template>

<style scoped lang="scss"></style>
